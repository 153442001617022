export function match(param: string) {
  return [
    "partnership-application-form",
    "volunteer-application-form",
    "quotation-request",
    "demo-request-form",
    "pricing-request-form",
    "product-enquiry-form",
    "event-feedback-form",
    "software-feedback-survey",
  ]
    .includes(param);
}

// I am creating a form template gallery. Each page contains an image of a form template, a title, different sections of the form, and other content. For each form template, my goal is to provide the most helpful and useful content, relevant to that template. For a template titled, "Course evaluation form", give me 20 bullet points for things I should add to the page, that are highly relevant to that form template.
