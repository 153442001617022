export function match(param: string) {
  return [
    "customer-satisfaction-survey-form",
    "job-application-form",
    "training-feedback-survey-form",
    "course-evaluation-form",
    "car-loan-application-form",
    "employee-onboarding-form",
    "refund-request-form",
  ]
    .includes(param);
}

// I am creating a form template gallery. Each page contains an image of a form template, a title, different sections of the form, and other content. For each form template, my goal is to provide the most helpful and useful content, relevant to that template. For a template titled, "Course evaluation form", give me 20 bullet points for things I should add to the page, that are highly relevant to that form template.
